<template>
  <v-app>
    <!-- begin:: Content Head -->
    <KTSubheader v-bind:title="'Calls'" />
    <!-- end:: Content Head -->
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark"
            >{{ id ? "Edit" : "Create" }} call</span
          >
        </h3>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0 pb-3">
        <div class="tab-content">
          <b-form @submit="onSubmit" v-if="show">
            <b-form-row>
              <b-col md="4">
                <b-form-group
                  id="user-group"
                  label="User:"
                  label-for="user-input"
                >
                  <b-form-select
                    id="user-input"
                    v-model="form.userId"
                    :options="usersList"
                    required
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  id="from-group"
                  label="Direction:"
                  label-for="from-input"
                >
                  <b-form-input
                    id="from-input"
                    v-model="form.from"
                    required
                    placeholder="Enter from"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>

            <b-form-row>
              <b-col md="4">
                <b-form-group
                  id="start-time-group"
                  label="Start Time:"
                  label-for="start-time-input"
                >
                  <date-picker
                    id="start-time-input"
                    type="datetime"
                    v-model="form.startTime"
                  ></date-picker>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  id="end-time-group"
                  label="End Time:"
                  label-for="end-time-input"
                >
                  <date-picker
                    id="end-time-input"
                    type="datetime"
                    v-model="form.endTime"
                  ></date-picker>
                </b-form-group>
              </b-col>
            </b-form-row>

            <b-form-row>
              <b-col md="4">
                <b-form-group
                  id="duration-group"
                  label="Duration:"
                  label-for="duration-input"
                >
                  <b-form-input
                    id="duration-input"
                    v-model="form.duration"
                    type="number"
                    min="0"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  id="total-duration-group"
                  label="Total Duration:"
                  label-for="total-duration-input"
                >
                  <b-form-input
                    id="total-duration-input"
                    v-model="form.totalDuration"
                    type="number"
                    min="0"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>

            <b-form-row>
              <b-col md="4">
                <b-form-group
                  id="platform-group"
                  label="Platform:"
                  label-for="platform-input"
                >
                  <b-form-select
                    id="platform-input"
                    style="width: 70%"
                    v-model="form.platform"
                    :options="platforms"
                    required
                  ></b-form-select>
                  <v-btn
                    class="mx-2"
                    fab
                    x-small
                    color="#0f2b69"
                    @click="showCreateDropdownModal('platform', 'Platform')"
                  >
                    <v-icon style="color: white">mdi-plus</v-icon>
                  </v-btn>
                  <v-btn
                    class="mx-2"
                    fab
                    x-small
                    color="#F64E60"
                    v-if="form.platform"
                    @click="showDeleteDropdownModal(form.platform)"
                  >
                    <v-icon style="color: white">mdi-minus</v-icon>
                  </v-btn>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  id="gender-group"
                  label="Gender:"
                  label-for="gender-input"
                >
                  <b-form-select
                    id="gender-input"
                    style="width: 70%"
                    v-model="form.gender"
                    :options="genders"
                    required
                  ></b-form-select>
                  <v-btn
                    class="mx-2"
                    fab
                    x-small
                    color="#0f2b69"
                    @click="showCreateDropdownModal('gender', 'Gender')"
                  >
                    <v-icon style="color: white">mdi-plus</v-icon>
                  </v-btn>
                  <v-btn
                    class="mx-2"
                    fab
                    x-small
                    color="#F64E60"
                    v-if="form.gender"
                    @click="showDeleteDropdownModal(form.gender)"
                  >
                    <v-icon style="color: white">mdi-minus</v-icon>
                  </v-btn>
                </b-form-group>
              </b-col>
            </b-form-row>

            <b-form-row>
              <b-col md="4">
                <b-form-group
                  id="country-group"
                  label="Country of Origin:"
                  label-for="country-input"
                >
                  <b-form-select
                    id="country-input"
                    v-model="form.country"
                    :options="countries"
                    required
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  id="municipality-group"
                  label="Municipality:"
                  label-for="municipality-input"
                >
                  <b-form-input
                    id="municipality-input"
                    v-model="form.municipality"
                    placeholder="Enter Municipality..."
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>

            <b-form-row>
              <b-col md="4">
                <b-form-group
                  id="language-group"
                  label="Language:"
                  label-for="language-input"
                >
                  <b-form-select
                    id="language-input"
                    v-model="form.language"
                    :options="languages"
                    required
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  id="location-group"
                  label="Current Location:"
                  label-for="location-input"
                >
                  <b-form-select
                    id="location-input"
                    style="width: 70%"
                    v-model="form.currentLocation"
                    :options="locations"
                    required
                  ></b-form-select>
                  <v-btn
                    class="mx-2"
                    fab
                    x-small
                    color="#0f2b69"
                    @click="showCreateDropdownModal('location', 'Location')"
                  >
                    <v-icon style="color: white">mdi-plus</v-icon>
                  </v-btn>
                  <v-btn
                    class="mx-2"
                    fab
                    x-small
                    color="#F64E60"
                    v-if="form.currentLocation"
                    @click="showDeleteDropdownModal(form.currentLocation)"
                  >
                    <v-icon style="color: white">mdi-minus</v-icon>
                  </v-btn>
                </b-form-group>
              </b-col>
            </b-form-row>

            <b-form-row>
              <b-col md="4">
                <b-form-group
                  id="reason-group"
                  label="Reason for Call:"
                  label-for="reason-input"
                >
                  <b-form-select
                    id="reason-input"
                    style="width: 70%"
                    v-model="form.reasonForCall"
                    :options="reasonsForCall"
                    required
                  ></b-form-select>
                  <v-btn
                    class="mx-2"
                    fab
                    x-small
                    color="#0f2b69"
                    @click="
                      showCreateDropdownModal(
                        'reasonForCall',
                        'Reason For Call'
                      )
                    "
                  >
                    <v-icon style="color: white">mdi-plus</v-icon>
                  </v-btn>
                  <v-btn
                    class="mx-2"
                    fab
                    x-small
                    color="#F64E60"
                    v-if="form.reasonForCall"
                    @click="showDeleteDropdownModal(form.reasonForCall)"
                  >
                    <v-icon style="color: white">mdi-minus</v-icon>
                  </v-btn>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  id="heard-about-group"
                  label="How did you heard about us?"
                  label-for="heard-about-input"
                >
                  <b-form-select
                    id="reason-input"
                    style="width: 70%"
                    v-model="form.heardAbout"
                    :options="heardAboutOptions"
                  ></b-form-select>
                  <v-btn
                    class="mx-2"
                    fab
                    x-small
                    color="#0f2b69"
                    @click="
                      showCreateDropdownModal(
                        'heardAbout',
                        'How did you heard about us?'
                      )
                    "
                  >
                    <v-icon style="color: white">mdi-plus</v-icon>
                  </v-btn>
                  <v-btn
                    class="mx-2"
                    fab
                    x-small
                    color="#F64E60"
                    v-if="form.heardAbout"
                    @click="showDeleteDropdownModal(form.heardAbout)"
                  >
                    <v-icon style="color: white">mdi-minus</v-icon>
                  </v-btn>
                </b-form-group>
              </b-col>
            </b-form-row>

            <b-form-row>
              <b-col md="4">
                <b-form-group
                  id="details-group"
                  label="Details:"
                  label-for="details-input"
                >
                  <b-form-textarea
                    id="details-input"
                    v-model="form.details"
                    placeholder="Enter details..."
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-form-row>

            <router-link :to="{ name: 'calls' }">
              <b-button type="button" variant="secondary">Cancel</b-button>
            </router-link>
            <b-button type="submit" variant="primary" class="ml-3"
              >Submit</b-button
            >
          </b-form>
        </div>
      </div>
    </div>
    <!--end::Body-->

    <!--start::CreateDropdownModal-->
    <b-modal ref="create-dropdown-modal" hide-footer title="Create dropdown">
      <b-form @submit="createDropdown" v-if="show">
        <div class="d-block text-center">
          <b-form-group
            id="dropdown-value-group"
            :label="dropdownForm.typeLabel + ':'"
            label-for="from-input"
          >
            <b-form-input
              id="dropdown-value-input"
              v-model="dropdownForm.value"
              required
              placeholder="Enter name"
            ></b-form-input>
          </b-form-group>
        </div>
        <footer class="modal-footer">
          <b-button
            type="button"
            variant="secondary"
            @click="hideCreateDropdownModal"
            >Close</b-button
          >
          <b-button type="submit" variant="primary">Save</b-button>
        </footer>
      </b-form>
    </b-modal>
    <!--end::CreateDropdownModal-->

    <!--start::DeleteDropdownModal-->
    <b-modal ref="delete-dropdown-modal" hide-footer title="Delete dropdown">
      <b-form
        @submit="deleteDropdown(currentDropdown.id)"
        v-if="show && currentDropdown"
      >
        <div class="d-block text-center">
          Are you sure that you want to delete
          <b>{{ currentDropdown.value }}</b>
        </div>
        <footer class="modal-footer">
          <b-button
            type="button"
            variant="secondary"
            @click="hideCreateDropdownModal"
            >Close</b-button
          >
          <b-button type="submit" variant="primary">Delete</b-button>
        </footer>
      </b-form>
    </b-modal>
    <!--end::DeleteDropdownModal-->
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import KTSubheader from "@/view/layout/subheader/Subheader.vue";
import {
  GET_CALL,
  CREATE_CALL,
  UPDATE_CALL
} from "@/core/services/store/call.module.js";
import {
  GET_DROPDOWNS_LIST,
  CREATE_DROPDOWN,
  DELETE_DROPDOWN
} from "@/core/services/store/dropdown.module.js";
import { GET_USERS_LIST } from "@/core/services/store/user.module.js";
import countryService from "@/core/services/country.service.js";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "edit-call",
  components: {
    KTSubheader,
    DatePicker
  },
  data: () => ({
    id: null,
    form: {
      userId: null,
      from: "",
      startTime: "",
      endTime: "",
      status: "",
      duration: "0",
      totalDuration: "0",
      platform: "",
      gender: "",
      country: "",
      municipality: "",
      language: "",
      currentLocation: "",
      reasonForCall: "",
      heardAbout: "",
      details: ""
    },
    dropdownForm: {
      type: "platform",
      typeLabel: "Platform",
      value: "",
      id: null
    },
    countries: countryService.countries,
    languages: countryService.languages,
    currentDropdown: null,
    show: true
  }),
  async mounted() {
    this.id = this.$route.params.id;
    if (this.id) {
      await this.$store.dispatch(GET_CALL, this.id);

      const data = this.call;
      if (data.startTime) {
        data.startTime = new Date(data.startTime);
      }
      if (data.endTime) {
        data.endTime = new Date(data.endTime);
      }

      this.form = data;
    }

    this.$store.dispatch(GET_USERS_LIST);
    this.$store.dispatch(GET_DROPDOWNS_LIST);
  },
  computed: {
    ...mapState({
      call: state => state.call.call,
      usersList: state =>
        state.user.users.map(user => {
          return { value: user.id, text: user.firstName + " " + user.lastName };
        }),
      dropdownsList: state => state.dropdown.dropdowns
    }),
    platforms() {
      return this.dropdownsList
        .filter(item => item.type === "platform")
        .map(item => {
          return { value: item.value, text: item.value, id: item.id };
        });
    },
    genders() {
      return this.dropdownsList
        .filter(item => item.type === "gender")
        .map(item => {
          return { value: item.value, text: item.value, id: item.id };
        });
    },
    heardAboutOptions() {
      return this.dropdownsList
        .filter(item => item.type === "heardAbout")
        .map(item => {
          return { value: item.value, text: item.value, id: item.id };
        });
    },
    locations() {
      return this.dropdownsList
        .filter(item => item.type === "location")
        .map(item => {
          return { value: item.value, text: item.value, id: item.id };
        });
    },
    reasonsForCall() {
      return this.dropdownsList
        .filter(item => item.type === "reasonForCall")
        .map(item => {
          return { value: item.value, text: item.value, id: item.id };
        });
    }
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      if (this.id) {
        this.$store
          .dispatch(UPDATE_CALL, { id: this.id, ...this.form })
          .then(data => {
            this.$router.push("/calls");
          })
          .catch(error => {});
      } else {
        this.$store
          .dispatch(CREATE_CALL, this.form)
          .then(data => {
            this.$router.push("/calls");
          })
          .catch(error => {});
      }
    },
    showCreateDropdownModal(type, typeLabel) {
      this.dropdownForm = {
        type: type,
        typeLabel: typeLabel,
        value: ""
      };
      this.$refs["create-dropdown-modal"].show();
    },
    hideCreateDropdownModal() {
      this.$refs["create-dropdown-modal"].hide();
    },
    showDeleteDropdownModal(dropdownValue) {
      const dropdown = this.dropdownsList.find(
        item => item.value === dropdownValue
      );

      if (!dropdown) return;
      this.currentDropdown = dropdown;
      this.$refs["delete-dropdown-modal"].show();
    },
    hideDeleteDropdownModal() {
      this.$refs["delete-dropdown-modal"].hide();
    },
    createDropdown(evt) {
      evt.preventDefault();

      this.$store
        .dispatch(CREATE_DROPDOWN, this.dropdownForm)
        .then(data => {
          this.hideCreateDropdownModal();
          this.dropdownForm.value = "";
        })
        .catch(error => {});
    },
    deleteDropdown(id) {
      this.$store
        .dispatch(DELETE_DROPDOWN, { id })
        .then(data => {
          this.hideDeleteDropdownModal();
        })
        .catch(error => {});
    }
  }
};
</script>
