const countryService = {
  countries: [
    {
      value: "Afghanistan",
      text: "Afghanistan"
    },
    {
      value: "Aland Islands",
      text: "Aland Islands"
    },
    {
      value: "Albania",
      text: "Albania"
    },
    {
      value: "Algeria",
      text: "Algeria"
    },
    {
      value: "American Samoa",
      text: "American Samoa"
    },
    {
      value: "AndorrA",
      text: "AndorrA"
    },
    {
      value: "Angola",
      text: "Angola"
    },
    {
      value: "Anguilla",
      text: "Anguilla"
    },
    {
      value: "Antarctica",
      text: "Antarctica"
    },
    {
      value: "Antigua and Barbuda",
      text: "Antigua and Barbuda"
    },
    {
      value: "Argentina",
      text: "Argentina"
    },
    {
      value: "Armenia",
      text: "Armenia"
    },
    {
      value: "Aruba",
      text: "Aruba"
    },
    {
      value: "Australia",
      text: "Australia"
    },
    {
      value: "Austria",
      text: "Austria"
    },
    {
      value: "Azerbaijan",
      text: "Azerbaijan"
    },
    {
      value: "Bahamas",
      text: "Bahamas"
    },
    {
      value: "Bahrain",
      text: "Bahrain"
    },
    {
      value: "Bangladesh",
      text: "Bangladesh"
    },
    {
      value: "Barbados",
      text: "Barbados"
    },
    {
      value: "Belarus",
      text: "Belarus"
    },
    {
      value: "Belgium",
      text: "Belgium"
    },
    {
      value: "Belize",
      text: "Belize"
    },
    {
      value: "Benin",
      text: "Benin"
    },
    {
      value: "Bermuda",
      text: "Bermuda"
    },
    {
      value: "Bhutan",
      text: "Bhutan"
    },
    {
      value: "Bolivia",
      text: "Bolivia"
    },
    {
      value: "Bosnia and Herzegovina",
      text: "Bosnia and Herzegovina"
    },
    {
      value: "Botswana",
      text: "Botswana"
    },
    {
      value: "Bouvet Island",
      text: "Bouvet Island"
    },
    {
      value: "Brazil",
      text: "Brazil"
    },
    {
      value: "British Indian Ocean Territory",
      text: "British Indian Ocean Territory"
    },
    {
      value: "Brunei Darussalam",
      text: "Brunei Darussalam"
    },
    {
      value: "Bulgaria",
      text: "Bulgaria"
    },
    {
      value: "Burkina Faso",
      text: "Burkina Faso"
    },
    {
      value: "Burundi",
      text: "Burundi"
    },
    {
      value: "Cambodia",
      text: "Cambodia"
    },
    {
      value: "Cameroon",
      text: "Cameroon"
    },
    {
      value: "Canada",
      text: "Canada"
    },
    {
      value: "Cape Verde",
      text: "Cape Verde"
    },
    {
      value: "Cayman Islands",
      text: "Cayman Islands"
    },
    {
      value: "Central African Republic",
      text: "Central African Republic"
    },
    {
      value: "Chad",
      text: "Chad"
    },
    {
      value: "Chile",
      text: "Chile"
    },
    {
      value: "China",
      text: "China"
    },
    {
      value: "Christmas Island",
      text: "Christmas Island"
    },
    {
      value: "Cocos (Keeling) Islands",
      text: "Cocos (Keeling) Islands"
    },
    {
      value: "Colombia",
      text: "Colombia"
    },
    {
      value: "Comoros",
      text: "Comoros"
    },
    {
      value: "Congo",
      text: "Congo"
    },
    {
      value: "Congo, The Democratic Republic of the",
      text: "Congo, The Democratic Republic of the"
    },
    {
      value: "Cook Islands",
      text: "Cook Islands"
    },
    {
      value: "Costa Rica",
      text: "Costa Rica"
    },
    {
      value: "Cote D'Ivoire",
      text: "Cote D'Ivoire"
    },
    {
      value: "Croatia",
      text: "Croatia"
    },
    {
      value: "Cuba",
      text: "Cuba"
    },
    {
      value: "Cyprus",
      text: "Cyprus"
    },
    {
      value: "Czech Republic",
      text: "Czech Republic"
    },
    {
      value: "Denmark",
      text: "Denmark"
    },
    {
      value: "Djibouti",
      text: "Djibouti"
    },
    {
      value: "Dominica",
      text: "Dominica"
    },
    {
      value: "Dominican Republic",
      text: "Dominican Republic"
    },
    {
      value: "Ecuador",
      text: "Ecuador"
    },
    {
      value: "Egypt",
      text: "Egypt"
    },
    {
      value: "El Salvador",
      text: "El Salvador"
    },
    {
      value: "Equatorial Guinea",
      text: "Equatorial Guinea"
    },
    {
      value: "Eritrea",
      text: "Eritrea"
    },
    {
      value: "Estonia",
      text: "Estonia"
    },
    {
      value: "Ethiopia",
      text: "Ethiopia"
    },
    {
      value: "Falkland Islands (Malvinas)",
      text: "Falkland Islands (Malvinas)"
    },
    {
      value: "Faroe Islands",
      text: "Faroe Islands"
    },
    {
      value: "Fiji",
      text: "Fiji"
    },
    {
      value: "Finland",
      text: "Finland"
    },
    {
      value: "France",
      text: "France"
    },
    {
      value: "French Guiana",
      text: "French Guiana"
    },
    {
      value: "French Polynesia",
      text: "French Polynesia"
    },
    {
      value: "French Southern Territories",
      text: "French Southern Territories"
    },
    {
      value: "Gabon",
      text: "Gabon"
    },
    {
      value: "Gambia",
      text: "Gambia"
    },
    {
      value: "Georgia",
      text: "Georgia"
    },
    {
      value: "Germany",
      text: "Germany"
    },
    {
      value: "Ghana",
      text: "Ghana"
    },
    {
      value: "Gibraltar",
      text: "Gibraltar"
    },
    {
      value: "Greece",
      text: "Greece"
    },
    {
      value: "Greenland",
      text: "Greenland"
    },
    {
      value: "Grenada",
      text: "Grenada"
    },
    {
      value: "Guadeloupe",
      text: "Guadeloupe"
    },
    {
      value: "Guam",
      text: "Guam"
    },
    {
      value: "Guatemala",
      text: "Guatemala"
    },
    {
      value: "Guernsey",
      text: "Guernsey"
    },
    {
      value: "Guinea",
      text: "Guinea"
    },
    {
      value: "Guinea-Bissau",
      text: "Guinea-Bissau"
    },
    {
      value: "Guyana",
      text: "Guyana"
    },
    {
      value: "Haiti",
      text: "Haiti"
    },
    {
      value: "Heard Island and Mcdonald Islands",
      text: "Heard Island and Mcdonald Islands"
    },
    {
      value: "Holy See (Vatican City State)",
      text: "Holy See (Vatican City State)"
    },
    {
      value: "Honduras",
      text: "Honduras"
    },
    {
      value: "Hong Kong",
      text: "Hong Kong"
    },
    {
      value: "Hungary",
      text: "Hungary"
    },
    {
      value: "Iceland",
      text: "Iceland"
    },
    {
      value: "India",
      text: "India"
    },
    {
      value: "Indonesia",
      text: "Indonesia"
    },
    {
      value: "Iran, Islamic Republic Of",
      text: "Iran, Islamic Republic Of"
    },
    {
      value: "Iraq",
      text: "Iraq"
    },
    {
      value: "Ireland",
      text: "Ireland"
    },
    {
      value: "Isle of Man",
      text: "Isle of Man"
    },
    {
      value: "Israel",
      text: "Israel"
    },
    {
      value: "Italy",
      text: "Italy"
    },
    {
      value: "Jamaica",
      text: "Jamaica"
    },
    {
      value: "Japan",
      text: "Japan"
    },
    {
      value: "Jersey",
      text: "Jersey"
    },
    {
      value: "Jordan",
      text: "Jordan"
    },
    {
      value: "Kazakhstan",
      text: "Kazakhstan"
    },
    {
      value: "Kenya",
      text: "Kenya"
    },
    {
      value: "Kiribati",
      text: "Kiribati"
    },
    {
      value: "Korea, Democratic People'S Republic of",
      text: "Korea, Democratic People'S Republic of"
    },
    {
      value: "Korea, Republic of",
      text: "Korea, Republic of"
    },
    {
      value: "Kosovo",
      text: "Kosovo"
    },
    {
      value: "Kuwait",
      text: "Kuwait"
    },
    {
      value: "Kyrgyzstan",
      text: "Kyrgyzstan"
    },
    {
      value: "Lao People'S Democratic Republic",
      text: "Lao People'S Democratic Republic"
    },
    {
      value: "Latvia",
      text: "Latvia"
    },
    {
      value: "Lebanon",
      text: "Lebanon"
    },
    {
      value: "Lesotho",
      text: "Lesotho"
    },
    {
      value: "Liberia",
      text: "Liberia"
    },
    {
      value: "Libyan Arab Jamahiriya",
      text: "Libyan Arab Jamahiriya"
    },
    {
      value: "Liechtenstein",
      text: "Liechtenstein"
    },
    {
      value: "Lithuania",
      text: "Lithuania"
    },
    {
      value: "Luxembourg",
      text: "Luxembourg"
    },
    {
      value: "Macao",
      text: "Macao"
    },
    {
      value: "Macedonia, The Former Yugoslav Republic of",
      text: "Macedonia, The Former Yugoslav Republic of"
    },
    {
      value: "Madagascar",
      text: "Madagascar"
    },
    {
      value: "Malawi",
      text: "Malawi"
    },
    {
      value: "Malaysia",
      text: "Malaysia"
    },
    {
      value: "Maldives",
      text: "Maldives"
    },
    {
      value: "Mali",
      text: "Mali"
    },
    {
      value: "Malta",
      text: "Malta"
    },
    {
      value: "Marshall Islands",
      text: "Marshall Islands"
    },
    {
      value: "Martinique",
      text: "Martinique"
    },
    {
      value: "Mauritania",
      text: "Mauritania"
    },
    {
      value: "Mauritius",
      text: "Mauritius"
    },
    {
      value: "Mayotte",
      text: "Mayotte"
    },
    {
      value: "Mexico",
      text: "Mexico"
    },
    {
      value: "Micronesia, Federated States of",
      text: "Micronesia, Federated States of"
    },
    {
      value: "Moldova, Republic of",
      text: "Moldova, Republic of"
    },
    {
      value: "Monaco",
      text: "Monaco"
    },
    {
      value: "Mongolia",
      text: "Mongolia"
    },
    {
      value: "Montserrat",
      text: "Montserrat"
    },
    {
      value: "Morocco",
      text: "Morocco"
    },
    {
      value: "Mozambique",
      text: "Mozambique"
    },
    {
      value: "Myanmar",
      text: "Myanmar"
    },
    {
      value: "Namibia",
      text: "Namibia"
    },
    {
      value: "Nauru",
      text: "Nauru"
    },
    {
      value: "Nepal",
      text: "Nepal"
    },
    {
      value: "Netherlands",
      text: "Netherlands"
    },
    {
      value: "Netherlands Antilles",
      text: "Netherlands Antilles"
    },
    {
      value: "New Caledonia",
      text: "New Caledonia"
    },
    {
      value: "New Zealand",
      text: "New Zealand"
    },
    {
      value: "Nicaragua",
      text: "Nicaragua"
    },
    {
      value: "Niger",
      text: "Niger"
    },
    {
      value: "Nigeria",
      text: "Nigeria"
    },
    {
      value: "Niue",
      text: "Niue"
    },
    {
      value: "Norfolk Island",
      text: "Norfolk Island"
    },
    {
      value: "Northern Mariana Islands",
      text: "Northern Mariana Islands"
    },
    {
      value: "Norway",
      text: "Norway"
    },
    {
      value: "Oman",
      text: "Oman"
    },
    {
      value: "Pakistan",
      text: "Pakistan"
    },
    {
      value: "Palau",
      text: "Palau"
    },
    {
      value: "Palestinian Territory, Occupied",
      text: "Palestinian Territory, Occupied"
    },
    {
      value: "Panama",
      text: "Panama"
    },
    {
      value: "Papua New Guinea",
      text: "Papua New Guinea"
    },
    {
      value: "Paraguay",
      text: "Paraguay"
    },
    {
      value: "Peru",
      text: "Peru"
    },
    {
      value: "Philippines",
      text: "Philippines"
    },
    {
      value: "Pitcairn",
      text: "Pitcairn"
    },
    {
      value: "Poland",
      text: "Poland"
    },
    {
      value: "Portugal",
      text: "Portugal"
    },
    {
      value: "Puerto Rico",
      text: "Puerto Rico"
    },
    {
      value: "Qatar",
      text: "Qatar"
    },
    {
      value: "Reunion",
      text: "Reunion"
    },
    {
      value: "Romania",
      text: "Romania"
    },
    {
      value: "Russian Federation",
      text: "Russian Federation"
    },
    {
      value: "RWANDA",
      text: "RWANDA"
    },
    {
      value: "Saint Helena",
      text: "Saint Helena"
    },
    {
      value: "Saint Kitts and Nevis",
      text: "Saint Kitts and Nevis"
    },
    {
      value: "Saint Lucia",
      text: "Saint Lucia"
    },
    {
      value: "Saint Pierre and Miquelon",
      text: "Saint Pierre and Miquelon"
    },
    {
      value: "Saint Vincent and the Grenadines",
      text: "Saint Vincent and the Grenadines"
    },
    {
      value: "Samoa",
      text: "Samoa"
    },
    {
      value: "San Marino",
      text: "San Marino"
    },
    {
      value: "Sao Tome and Principe",
      text: "Sao Tome and Principe"
    },
    {
      value: "Saudi Arabia",
      text: "Saudi Arabia"
    },
    {
      value: "Senegal",
      text: "Senegal"
    },
    {
      value: "Serbia and Montenegro",
      text: "Serbia and Montenegro"
    },
    {
      value: "Seychelles",
      text: "Seychelles"
    },
    {
      value: "Sierra Leone",
      text: "Sierra Leone"
    },
    {
      value: "Singapore",
      text: "Singapore"
    },
    {
      value: "Slovakia",
      text: "Slovakia"
    },
    {
      value: "Slovenia",
      text: "Slovenia"
    },
    {
      value: "Solomon Islands",
      text: "Solomon Islands"
    },
    {
      value: "Somalia",
      text: "Somalia"
    },
    {
      value: "South Africa",
      text: "South Africa"
    },
    {
      value: "South Georgia and the South Sandwich Islands",
      text: "South Georgia and the South Sandwich Islands"
    },
    {
      value: "Spain",
      text: "Spain"
    },
    {
      value: "Sri Lanka",
      text: "Sri Lanka"
    },
    {
      value: "Sudan",
      text: "Sudan"
    },
    {
      value: "Suriname",
      text: "Suriname"
    },
    {
      value: "Svalbard and Jan Mayen",
      text: "Svalbard and Jan Mayen"
    },
    {
      value: "Swaziland",
      text: "Swaziland"
    },
    {
      value: "Sweden",
      text: "Sweden"
    },
    {
      value: "Switzerland",
      text: "Switzerland"
    },
    {
      value: "Syrian Arab Republic",
      text: "Syrian Arab Republic"
    },
    {
      value: "Taiwan, Province of China",
      text: "Taiwan, Province of China"
    },
    {
      value: "Tajikistan",
      text: "Tajikistan"
    },
    {
      value: "Tanzania, United Republic of",
      text: "Tanzania, United Republic of"
    },
    {
      value: "Thailand",
      text: "Thailand"
    },
    {
      value: "Timor-Leste",
      text: "Timor-Leste"
    },
    {
      value: "Togo",
      text: "Togo"
    },
    {
      value: "Tokelau",
      text: "Tokelau"
    },
    {
      value: "Tonga",
      text: "Tonga"
    },
    {
      value: "Trinidad and Tobago",
      text: "Trinidad and Tobago"
    },
    {
      value: "Tunisia",
      text: "Tunisia"
    },
    {
      value: "Turkey",
      text: "Turkey"
    },
    {
      value: "Turkmenistan",
      text: "Turkmenistan"
    },
    {
      value: "Turks and Caicos Islands",
      text: "Turks and Caicos Islands"
    },
    {
      value: "Tuvalu",
      text: "Tuvalu"
    },
    {
      value: "Uganda",
      text: "Uganda"
    },
    {
      value: "Ukraine",
      text: "Ukraine"
    },
    {
      value: "United Arab Emirates",
      text: "United Arab Emirates"
    },
    {
      value: "United Kingdom",
      text: "United Kingdom"
    },
    {
      value: "United States",
      text: "United States"
    },
    {
      value: "United States Minor Outlying Islands",
      text: "United States Minor Outlying Islands"
    },
    {
      value: "Uruguay",
      text: "Uruguay"
    },
    {
      value: "Uzbekistan",
      text: "Uzbekistan"
    },
    {
      value: "Vanuatu",
      text: "Vanuatu"
    },
    {
      value: "Venezuela",
      text: "Venezuela"
    },
    {
      value: "Viet Nam",
      text: "Viet Nam"
    },
    {
      value: "Virgin Islands, British",
      text: "Virgin Islands, British"
    },
    {
      value: "Virgin Islands, U.S.",
      text: "Virgin Islands, U.S."
    },
    {
      value: "Wallis and Futuna",
      text: "Wallis and Futuna"
    },
    {
      value: "Western Sahara",
      text: "Western Sahara"
    },
    {
      value: "Yemen",
      text: "Yemen"
    },
    {
      value: "Zambia",
      text: "Zambia"
    },
    {
      value: "Zimbabwe",
      text: "Zimbabwe"
    }
  ],

  languages: [
    {
      value: "Abkhaz",
      text: "Abkhaz"
    },
    {
      value: "Afar",
      text: "Afar"
    },
    {
      value: "Afrikaans",
      text: "Afrikaans"
    },
    {
      value: "Akan",
      text: "Akan"
    },
    {
      value: "Albanian",
      text: "Albanian"
    },
    {
      value: "Amharic",
      text: "Amharic"
    },
    {
      value: "Arabic",
      text: "Arabic"
    },
    {
      value: "Aragonese",
      text: "Aragonese"
    },
    {
      value: "Armenian",
      text: "Armenian"
    },
    {
      value: "Assamese",
      text: "Assamese"
    },
    {
      value: "Avaric",
      text: "Avaric"
    },
    {
      value: "Avestan",
      text: "Avestan"
    },
    {
      value: "Aymara",
      text: "Aymara"
    },
    {
      value: "Azerbaijani",
      text: "Azerbaijani"
    },
    {
      value: "Bambara",
      text: "Bambara"
    },
    {
      value: "Bashkir",
      text: "Bashkir"
    },
    {
      value: "Basque",
      text: "Basque"
    },
    {
      value: "Belarusian",
      text: "Belarusian"
    },
    {
      value: "Bengali",
      text: "Bengali"
    },
    {
      value: "Bihari",
      text: "Bihari"
    },
    {
      value: "Bislama",
      text: "Bislama"
    },
    {
      value: "Bosnian",
      text: "Bosnian"
    },
    {
      value: "Breton",
      text: "Breton"
    },
    {
      value: "Bulgarian",
      text: "Bulgarian"
    },
    {
      value: "Burmese",
      text: "Burmese"
    },
    {
      value: "Catalan; Valencian",
      text: "Catalan; Valencian"
    },
    {
      value: "Chamorro",
      text: "Chamorro"
    },
    {
      value: "Chechen",
      text: "Chechen"
    },
    {
      value: "Chichewa; Chewa; Nyanja",
      text: "Chichewa; Chewa; Nyanja"
    },
    {
      value: "Chinese",
      text: "Chinese"
    },
    {
      value: "Chuvash",
      text: "Chuvash"
    },
    {
      value: "Cornish",
      text: "Cornish"
    },
    {
      value: "Corsican",
      text: "Corsican"
    },
    {
      value: "Cree",
      text: "Cree"
    },
    {
      value: "Croatian",
      text: "Croatian"
    },
    {
      value: "Czech",
      text: "Czech"
    },
    {
      value: "Danish",
      text: "Danish"
    },
    {
      value: "Divehi; Dhivehi; Maldivian;",
      text: "Divehi; Dhivehi; Maldivian;"
    },
    {
      value: "Dutch",
      text: "Dutch"
    },
    {
      value: "English",
      text: "English"
    },
    {
      value: "Esperanto",
      text: "Esperanto"
    },
    {
      value: "Estonian",
      text: "Estonian"
    },
    {
      value: "Ewe",
      text: "Ewe"
    },
    {
      value: "Faroese",
      text: "Faroese"
    },
    {
      value: "Fijian",
      text: "Fijian"
    },
    {
      value: "Finnish",
      text: "Finnish"
    },
    {
      value: "French",
      text: "French"
    },
    {
      value: "Fula; Fulah; Pulaar; Pular",
      text: "Fula; Fulah; Pulaar; Pular"
    },
    {
      value: "Galician",
      text: "Galician"
    },
    {
      value: "Georgian",
      text: "Georgian"
    },
    {
      value: "German",
      text: "German"
    },
    {
      value: "Greek, Modern",
      text: "Greek, Modern"
    },
    {
      value: "Guaraní",
      text: "Guaraní"
    },
    {
      value: "Gujarati",
      text: "Gujarati"
    },
    {
      value: "Haitian; Haitian Creole",
      text: "Haitian; Haitian Creole"
    },
    {
      value: "Hausa",
      text: "Hausa"
    },
    {
      value: "Hebrew",
      text: "Hebrew"
    },
    {
      value: "Hebrew",
      text: "Hebrew"
    },
    {
      value: "Herero",
      text: "Herero"
    },
    {
      value: "Hindi",
      text: "Hindi"
    },
    {
      value: "Hiri Motu",
      text: "Hiri Motu"
    },
    {
      value: "Hungarian",
      text: "Hungarian"
    },
    {
      value: "Interlingua",
      text: "Interlingua"
    },
    {
      value: "Indonesian",
      text: "Indonesian"
    },
    {
      value: "Interlingue",
      text: "Interlingue"
    },
    {
      value: "Irish",
      text: "Irish"
    },
    {
      value: "Igbo",
      text: "Igbo"
    },
    {
      value: "Inupiaq",
      text: "Inupiaq"
    },
    {
      value: "Ido",
      text: "Ido"
    },
    {
      value: "Icelandic",
      text: "Icelandic"
    },
    {
      value: "Italian",
      text: "Italian"
    },
    {
      value: "Inuktitut",
      text: "Inuktitut"
    },
    {
      value: "Japanese",
      text: "Japanese"
    },
    {
      value: "Javanese",
      text: "Javanese"
    },
    {
      value: "Kalaallisut, Greenlandic",
      text: "Kalaallisut, Greenlandic"
    },
    {
      value: "Kannada",
      text: "Kannada"
    },
    {
      value: "Kanuri",
      text: "Kanuri"
    },
    {
      value: "Kashmiri",
      text: "Kashmiri"
    },
    {
      value: "Kazakh",
      text: "Kazakh"
    },
    {
      value: "Khmer",
      text: "Khmer"
    },
    {
      value: "Kikuyu, Gikuyu",
      text: "Kikuyu, Gikuyu"
    },
    {
      value: "Kinyarwanda",
      text: "Kinyarwanda"
    },
    {
      value: "Kirghiz, Kyrgyz",
      text: "Kirghiz, Kyrgyz"
    },
    {
      value: "Komi",
      text: "Komi"
    },
    {
      value: "Kongo",
      text: "Kongo"
    },
    {
      value: "Korean",
      text: "Korean"
    },
    {
      value: "Kurdish",
      text: "Kurdish"
    },
    {
      value: "Kwanyama, Kuanyama",
      text: "Kwanyama, Kuanyama"
    },
    {
      value: "Latin",
      text: "Latin"
    },
    {
      value: "Luxembourgish, Letzeburgesch",
      text: "Luxembourgish, Letzeburgesch"
    },
    {
      value: "Luganda",
      text: "Luganda"
    },
    {
      value: "Limburgish, Limburgan, Limburger",
      text: "Limburgish, Limburgan, Limburger"
    },
    {
      value: "Lingala",
      text: "Lingala"
    },
    {
      value: "Lao",
      text: "Lao"
    },
    {
      value: "Lithuanian",
      text: "Lithuanian"
    },
    {
      value: "Luba-Katanga",
      text: "Luba-Katanga"
    },
    {
      value: "Latvian",
      text: "Latvian"
    },
    {
      value: "Manx",
      text: "Manx"
    },
    {
      value: "Macedonian",
      text: "Macedonian"
    },
    {
      value: "Malagasy",
      text: "Malagasy"
    },
    {
      value: "Malay",
      text: "Malay"
    },
    {
      value: "Malayalam",
      text: "Malayalam"
    },
    {
      value: "Maltese",
      text: "Maltese"
    },
    {
      value: "Māori",
      text: "Māori"
    },
    {
      value: "Marathi (Marāṭhī)",
      text: "Marathi (Marāṭhī)"
    },
    {
      value: "Marshallese",
      text: "Marshallese"
    },
    {
      value: "Mongolian",
      text: "Mongolian"
    },
    {
      value: "Nauru",
      text: "Nauru"
    },
    {
      value: "Navajo, Navaho",
      text: "Navajo, Navaho"
    },
    {
      value: "Norwegian Bokmål",
      text: "Norwegian Bokmål"
    },
    {
      value: "North Ndebele",
      text: "North Ndebele"
    },
    {
      value: "Nepali",
      text: "Nepali"
    },
    {
      value: "Ndonga",
      text: "Ndonga"
    },
    {
      value: "Norwegian Nynorsk",
      text: "Norwegian Nynorsk"
    },
    {
      value: "Norwegian",
      text: "Norwegian"
    },
    {
      value: "Nuosu",
      text: "Nuosu"
    },
    {
      value: "South Ndebele",
      text: "South Ndebele"
    },
    {
      value: "Occitan",
      text: "Occitan"
    },
    {
      value: "Ojibwe, Ojibwa",
      text: "Ojibwe, Ojibwa"
    },
    {
      value:
        "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
      text:
        "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic"
    },
    {
      value: "Oromo",
      text: "Oromo"
    },
    {
      value: "Oriya",
      text: "Oriya"
    },
    {
      value: "Ossetian, Ossetic",
      text: "Ossetian, Ossetic"
    },
    {
      value: "Panjabi, Punjabi",
      text: "Panjabi, Punjabi"
    },
    {
      value: "Pāli",
      text: "Pāli"
    },
    {
      value: "Persian",
      text: "Persian"
    },
    {
      value: "Polish",
      text: "Polish"
    },
    {
      value: "Pashto, Pushto",
      text: "Pashto, Pushto"
    },
    {
      value: "Portuguese",
      text: "Portuguese"
    },
    {
      value: "Quechua",
      text: "Quechua"
    },
    {
      value: "Romansh",
      text: "Romansh"
    },
    {
      value: "Kirundi",
      text: "Kirundi"
    },
    {
      value: "Romanian, Moldavian, Moldovan",
      text: "Romanian, Moldavian, Moldovan"
    },
    {
      value: "Russian",
      text: "Russian"
    },
    {
      value: "Sanskrit (Saṁskṛta)",
      text: "Sanskrit (Saṁskṛta)"
    },
    {
      value: "Sardinian",
      text: "Sardinian"
    },
    {
      value: "Sindhi",
      text: "Sindhi"
    },
    {
      value: "Northern Sami",
      text: "Northern Sami"
    },
    {
      value: "Samoan",
      text: "Samoan"
    },
    {
      value: "Sango",
      text: "Sango"
    },
    {
      value: "Serbian",
      text: "Serbian"
    },
    {
      value: "Scottish Gaelic; Gaelic",
      text: "Scottish Gaelic; Gaelic"
    },
    {
      value: "Shona",
      text: "Shona"
    },
    {
      value: "Sinhala, Sinhalese",
      text: "Sinhala, Sinhalese"
    },
    {
      value: "Slovak",
      text: "Slovak"
    },
    {
      value: "Slovene",
      text: "Slovene"
    },
    {
      value: "Somali",
      text: "Somali"
    },
    {
      value: "Southern Sotho",
      text: "Southern Sotho"
    },
    {
      value: "Spanish; Castilian",
      text: "Spanish; Castilian"
    },
    {
      value: "Sundanese",
      text: "Sundanese"
    },
    {
      value: "Swahili",
      text: "Swahili"
    },
    {
      value: "Swati",
      text: "Swati"
    },
    {
      value: "Swedish",
      text: "Swedish"
    },
    {
      value: "Tamil",
      text: "Tamil"
    },
    {
      value: "Telugu",
      text: "Telugu"
    },
    {
      value: "Tajik",
      text: "Tajik"
    },
    {
      value: "Thai",
      text: "Thai"
    },
    {
      value: "Tigrinya",
      text: "Tigrinya"
    },
    {
      value: "Tibetan Standard, Tibetan, Central",
      text: "Tibetan Standard, Tibetan, Central"
    },
    {
      value: "Turkmen",
      text: "Turkmen"
    },
    {
      value: "Tagalog",
      text: "Tagalog"
    },
    {
      value: "Tswana",
      text: "Tswana"
    },
    {
      value: "Tonga (Tonga Islands)",
      text: "Tonga (Tonga Islands)"
    },
    {
      value: "Turkish",
      text: "Turkish"
    },
    {
      value: "Tsonga",
      text: "Tsonga"
    },
    {
      value: "Tatar",
      text: "Tatar"
    },
    {
      value: "Twi",
      text: "Twi"
    },
    {
      value: "Tahitian",
      text: "Tahitian"
    },
    {
      value: "Uighur, Uyghur",
      text: "Uighur, Uyghur"
    },
    {
      value: "Ukrainian",
      text: "Ukrainian"
    },
    {
      value: "Urdu",
      text: "Urdu"
    },
    {
      value: "Uzbek",
      text: "Uzbek"
    },
    {
      value: "Venda",
      text: "Venda"
    },
    {
      value: "Vietnamese",
      text: "Vietnamese"
    },
    {
      value: "Volapük",
      text: "Volapük"
    },
    {
      value: "Walloon",
      text: "Walloon"
    },
    {
      value: "Welsh",
      text: "Welsh"
    },
    {
      value: "Wolof",
      text: "Wolof"
    },
    {
      value: "Western Frisian",
      text: "Western Frisian"
    },
    {
      value: "Xhosa",
      text: "Xhosa"
    },
    {
      value: "Yiddish",
      text: "Yiddish"
    },
    {
      value: "Yoruba",
      text: "Yoruba"
    },
    {
      value: "Zhuang, Chuang",
      text: "Zhuang, Chuang"
    }
  ]
};

export default countryService;
